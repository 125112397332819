import React from 'react';
// import PropTypes from 'prop-types';
import { BsHeaderTitle, VipPassForm } from 'components';
import { UiBsContentContainer } from 'styles';
import { UiClassVipPassPage } from './ClassVipPassPage.style';


/**
 * 快速通道頁面
 */

export const ClassVipPassPage = () => {
  return (
    <UiClassVipPassPage>
      <BsHeaderTitle title="班級管理" />
      <UiBsContentContainer >
        <VipPassForm />
      </UiBsContentContainer>
    </UiClassVipPassPage>
  );
};

// ClassVipPassPage.propTypes = {
// };


