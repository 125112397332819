import { stringify } from 'query-string';

export const urlFormat = ({
  origin = window.location.origin,
  pathname,
  query,
}) => {
  const url = new URL(origin);
  if (pathname) url.pathname = pathname;
  url.search = stringify(query, { arrayFormat: 'index' });
  return url.toString();
};