import { ROLES } from 'constants/index';
import { urlFormat } from 'utils/url';
import { useUuid } from './useUuid';

export const useCreateOneBoardUrl = () => {
  const [getUuid] = useUuid();
  const createUrl = (data, role) => {
    const { 
      sessionId,
      classType,
      teacherName,
      studentName,
      advisorName,
      teacherId,
      studentId,
      bookIds,
    } = data;

    const courseUrlFormat = ({ pathname = `${sessionId}/setup`, query }) => urlFormat({
      origin: process.env.REACT_APP_ONEBOARD_DOMAIN,
      pathname,
      query,
    });

    switch (role) {
      case ROLES.TEACHER:
        return courseUrlFormat({
          query: {
            role,
            classType,
            bookIds,
            userName: teacherName,
            userId: teacherId,
          }
        });
      case ROLES.STUDENT:
        return courseUrlFormat({
          query: {
            role,
            classType,
            userName: studentName,
            userId: studentId,
          }
        });
      case ROLES.ADVISOR:
        return courseUrlFormat({
          query: {
            role,
            classType,
            bookIds,
            userName: advisorName,
            userId: getUuid(),
          }
        });
      case ROLES.OBSERVER:
      default:
        return courseUrlFormat({
          pathname: `${sessionId}/${ROLES.OBSERVER}`,
          query: {
            role,
            classType,
            userName: getUuid(),
            userId: getUuid(),
          }
      });
    }
  };

  return {
    createUrl,
  };
};