/* eslint-disable max-len */
import React,{ useState,useEffect } from 'react';
import { Checkbox as MatCheckbox,Select as MatSelect,MenuItem } from '@material-ui/core';
import { Button,BsModal } from 'components';
import { useUser } from 'store/user';
import { Tabs as MatTabs,Tab as MatTab } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { UiPermissionSetting,UiTable } from './PermissionSetting.style';


/**
 * 權限設定頁面
 */

 const IDENTITY = {
  owner:'機構管理者',
  groupOwner:'導師',
  staff:'一般教師',
  parent:'家長',
  student:'學生',
 };

 const Tabs = withStyles({
  root: {
    borderBottom: '1px solid #f2f4f8',
    margin:'0',
    marginBottom: '17px',
  },
  indicator: {
    backgroundColor: '#1890ff',
    '& > span': {
      width: '100%',
    },
  },
})(MatTabs);

const NewSelect = withStyles({
  root: {
    '&:before':{
      border: 'none',
    }
  },
})(MatSelect);

const Tab = withStyles((theme) => ({
  root: {
    margin:'0',
    marginRight: '32px',
    '&:hover': {
      color: '#40a9ff',
      opacity: 1,
    },
    '&$selected': {
      color: '#1890ff',
    },
    '&:focus': {
      color: '#40a9ff',
    },
  },
  selected: {},
}))((props) => <MatTab disableRipple {...props} />);

const availableText = {
	session: '課程',
	brief: '簡介',
	announcement: '公告',
  freeCourse: '課外學習',
	post: '貼文',
	album: '相簿',
	mission: '作業',
  oneExam: '線上測驗',
  selfExam: '自主練習',
  multimediaLearning: '影音學習',
  supplementaryResource: '教學資源',
	counseling: '導師商談室',
  allService: '學習全資源',
	member: '成員',
	setting: '設定',
};

const options = Object.entries(availableText).map(([key,value])=>{
  return {
    name:value,
    value:key
  };
});

const Select = ({value,onChange,options,disabled}) => {
  return (
    <NewSelect
      value={value}
      onChange={onChange}
      disabled={disabled}
      autoWidth
    >
      {
        options.map(option=>{
          return (
            <MenuItem value={option.value} key={option.value}>{option.name}</MenuItem>
          );
        })
      }
    </NewSelect>
  );
};

export const PermissionSetting = () => {
  const [header,setHeader] = useState([
    {
      label:'簡介',
      code:'brief',
    },
    {
      label:'公告',
      code:'announcement',
    },
    {
      label:'課程',
      code:'session',
      isDisabled:true
    },
    {
      label:'貼文',
      code:'post',
    },
    {
      label:'相簿',
      code:'album',
    },
    {
      label:'作業',
      code:'mission',
    },
    {
      label:'導師商談室',
      code:'counseling',
    },
    {
      label:'成員',
      code:'member',
    },
    {
      label:'設定',
      code:'setting',
    },
    {
      label:'線上測驗',
      code:'oneExam',
    },
    {
      label:'自主練習',
      code: 'selfExam',
    },
    {
      label:'影音學習',
      code:'multimediaLearning',
    },
    {
      label:'教學資源',
      code:'supplementaryResource',
    },
    {
      label:'學習全資源',
      code:'allServices',
    }
  ]);
  const [isEdit,setIsEdit] = useState(false);
  const [ownerSettings,setOwnerSettings] = useState([]);
  const [settings,setSettings] = useState([]);
  const [isOpen,setIsOpen] = useState(false);
  const [isLoading,setIsLoading] = useState(false);
  const [tab,setTab] = useState(0);
  const [{ myOrganizationSetting : { availableNavigationFunctions,navigationFunctions,preservedNavigationFunctions } },{
    editOrganizationGroupControls,
    getOrganizationGroupAccess
  }] = useUser();

  const onHeaderChange = (head) => {
    let checkType = 'check';
    const { indeterminate,isChecked,code } = head;
    if(isChecked && !indeterminate){
      checkType = 'check';
    }else if(isChecked && indeterminate){
      checkType = 'indeterminate';
    }else{
      checkType = 'unCheck';
    }
    const nextHeader = header.map(item=>{
      if(item.code === code){
        if(checkType === 'unCheck'){
          item.isChecked = true;
        }else if(checkType === 'check'){
          item.isChecked = false;
        }else if(checkType === 'indeterminate'){
          item.isChecked = true;
          item.indeterminate = false;
        }
      }
      return item;
    });
    const nextSettings = settings.map(item=>{
        const nextGroupAccessControlSetting = item.navigationFunctions.map(nav=>{
            if(nav.code === code){
              nav.isChecked = (checkType === 'unCheck' || checkType === 'indeterminate') ? true : false;
            }
            return {
              ...nav,
            };
        });
        const options = item.navigationFunctions.filter((navigationFunction) => navigationFunction.isChecked).map(item=>{
          return {
            name:availableText[item.code],
            value:item.code
          };
        });
        return {
          ...item,
          groupAccessControlSetting:nextGroupAccessControlSetting,
          options
        };
    });
    setHeader(nextHeader);
    setSettings(nextSettings);
    setIsEdit(true);
  };
  const onSelectOwnerChange = (identity,value) => {
    const nextGroupAccessControlSetting = ownerSettings.map(setting=>{
      if(setting.identity === identity){
        return {
          ...setting,
          entryNavigationFunction:value
        };
      }
      return {
        ...setting
      };
    });
    setOwnerSettings(nextGroupAccessControlSetting);
    setIsEdit(true);
  };
  const onSelectChange = (identity,value) => {
    const nextGroupAccessControlSetting = settings.map(setting=>{
      if(setting.identity === identity){
        return {
          ...setting,
          entryNavigationFunction:value
        };
      }
      return {
        ...setting
      };
    });
    setSettings(nextGroupAccessControlSetting);
    setIsEdit(true);
  };

  const onChange = (identity,item) => {
    const nextGroupAccessControlSetting = settings.map(setting=>{
      if(setting.identity === identity){
        let navigationFunctions = [];
        navigationFunctions = setting.navigationFunctions.map(nav=>{
          if(nav.code === item.code){
            return {
              ...nav,
              isChecked:!nav.isChecked
            };
          }
          return {
            ...nav,
          };
        });
        const options = navigationFunctions.filter((navigationFunction) => navigationFunction.isChecked).map(item=>{
          return {
            name:availableText[item.code],
            value:item.code
          };
        });
        return {
          ...setting,
          navigationFunctions,
          options
        };
      }
      return {
        ...setting
      };
    });
    const nextHeader = header.map((head)=>{
      let isChecked = false;
      let indeterminate = false;
      let check = 0;
      let identityLength = 0;
      nextGroupAccessControlSetting.forEach((access,index,array)=>{
        identityLength = array.length;
        access.navigationFunctions.forEach(nav=>{
          if(nav.code === head.code && nav.isChecked){
            check += 1;
          }
        });
      });
      if(check === identityLength) {
        isChecked = true;
        indeterminate = false;
      }else if(check === 0){
        isChecked = false;
        indeterminate = false;
      }else{
        isChecked = true;
        indeterminate = true;
      }
      return {
        ...head,
        isChecked,
        indeterminate
      };
    });
    setHeader(nextHeader);
    setSettings(nextGroupAccessControlSetting);
    setIsEdit(true);
  };

  const modalToggle = () => {
    setIsOpen(!isOpen);
  };

  const onTabChange = (e,value) => setTab(value);

  const submitHandle = async () => {
    setIsLoading(true);
    const userSettings = settings.map(setting=>{
      const nextNavigationFunctions = setting.navigationFunctions.filter(nav=>nav.isChecked).map(item=>{
        return item.code;
      });
      return {
        identity:setting.identity,
        entryNavigationFunction:setting.entryNavigationFunction,
        navigationFunctions:nextNavigationFunctions
      };
    });
    const nextOwnerSetting = ownerSettings.map(setting=>{
      const nextNavigationFunctions = setting.navigationFunctions.filter(nav=>nav.isChecked).map(item=>{
        return item.code;
      });
      return {
        identity:setting.identity,
        entryNavigationFunction:setting.entryNavigationFunction,
        navigationFunctions:nextNavigationFunctions
      };
    });
    const payload = nextOwnerSetting.concat(userSettings);
    await editOrganizationGroupControls({
      groupAccessControlSetting:payload
    });
    setIsEdit(false);
    modalToggle();
    setIsLoading(false);
  };

  useEffect(()=>{
    getOrganizationGroupAccess();
  },[]);

  useEffect(()=>{
    if(!navigationFunctions) return;
    if(isEdit) return;
    const checkDisabled = new Set(preservedNavigationFunctions);
    const nextGroupAccessControlSetting = navigationFunctions.map((item)=>{
      const options = [];
      const nextNavigationFunctions = availableNavigationFunctions.map((navigationFunction)=>{
        let isChecked = false;
        let isDisabled = checkDisabled.has(navigationFunction);
        const target = new Set(item.navigationFunctions);
        if(target.has(navigationFunction)) {
          isChecked = true;
          options.push({
            name:availableText[navigationFunction],
            value:navigationFunction
          });
        }
        return {
          code:navigationFunction,
          isChecked,
          isDisabled,
        };
      });
      return {
        ...item,
        navigationFunctions:nextNavigationFunctions,
        options
      };
    }).filter(item=>{
      if(item.identity !== 'owner'){
        return item;
      }
      setOwnerSettings([item]);
    });
    const nextHeader = availableNavigationFunctions.map((head)=>{
      let isChecked = false;
      let isDisabled = false;
      let indeterminate = false;
      let check = 0;
      let identityLength = 0;
      if(checkDisabled.has(head)) isDisabled = true;
      nextGroupAccessControlSetting.forEach((access,index,array)=>{
        identityLength = array.length;
        access.navigationFunctions.forEach(nav=>{
          if(nav.code === head && nav.isChecked){
            check += 1;
          }
        });
      });
      if(check === identityLength) {
        isChecked = true;
        indeterminate = false;
      }else if(check === 0){
        isChecked = false;
        indeterminate = false;
      }else{
        isChecked = true;
        indeterminate = true;
      }
      return {
        code:head,
        label:availableText[head],
        isChecked,
        indeterminate,
        isDisabled
      };
    });
    setHeader(nextHeader);
    setSettings(nextGroupAccessControlSetting);
  },[isEdit,navigationFunctions]);

  return (
    <UiPermissionSetting>
        <Tabs value={tab} onChange={onTabChange}>
          <Tab label="權限頁面" />
          <Tab label="身份權限" disabled={true}/>
        </Tabs>
        <UiTable>
          <div className="title">
            <div>身份</div>
            <div>班級前台進入頁</div>
            <div>顯示左側功能列</div>
          </div>
          <div className="body">
            <div className="main">
             <div className="tr">
               <div className="th"></div>
               <div className="th"></div>
               {
                 header.map((item,index)=>{
                   return (
                    <label className="th" key={index}>
                      <MatCheckbox
                        color="primary"
                        checked={item.isChecked || false}
                        indeterminate={item.indeterminate || false}
                        onChange={()=>onHeaderChange(item)}
                        // disabled={item.code === 'session'}
                      />
                      {item.label}
                    </label>
                   );
                 })
               }
             </div>
             {
                ownerSettings.map((access,index)=>{
                  return (
                    <div className="tr" key={index}>
                      <div className="td">{IDENTITY[access.identity]}</div>
                      <div className="td">
                        <Select
                          options={options}
                          value={access.entryNavigationFunction}
                          onChange={e=>onSelectOwnerChange(access.identity,e.target.value)}
                        />
                      </div>
                      {
                        access.navigationFunctions.map((item,accessIndex)=>{
                          return (
                            <div className="td" key={accessIndex}>
                              <MatCheckbox
                                color="primary"
                                disabled={true}
                                checked={item.isChecked}
                              />
                            </div>
                          );
                        })
                      }
                    </div>
                  );
                })
             }
             {
               settings.map((access,index)=>{
                 return (
                   <div className="tr" key={index}>
                     <div className="td">{IDENTITY[access.identity]}</div>
                      <div className="td">
                        <Select
                          options={access.options}
                          value={access.entryNavigationFunction}
                          onChange={e=>onSelectChange(access.identity,e.target.value)}
                        />
                      </div>
                     {
                       access.navigationFunctions.map((item,accessIndex)=>{
                         return (
                          <div className="td" key={accessIndex}>
                            <MatCheckbox
                              color="primary"
                              disabled={item.isDisabled}
                              checked={item.isChecked}
                              onChange={()=>onChange(access.identity,item)}
                            />
                          </div>
                         );
                       })
                     }
                   </div>
                 );
               })
             }
            </div>
          </div>
        </UiTable>
        <div className="buttonBox">
          <Button
            disabled={!isEdit}
            onClick={()=>setIsEdit(false)}
            buttonColor="highlight"
          >
            取消
          </Button>
          <Button disabled={!isEdit} onClick={modalToggle}>送出</Button>
        </div>
        <BsModal open={isOpen} title="是否確定修改" onOk={submitHandle} onCancel={modalToggle} isLoading={isLoading}>
          是否確定修改權限設定？新的權限設定將覆蓋原有設定，資料送出後，動作將不可復原，確認修改請按確認。
        </BsModal>
    </UiPermissionSetting>
  );
};
