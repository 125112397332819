import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useRouteMatch, useParams } from 'react-router-dom';
// import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import { Button, Select } from 'components';
import { CRMField, CRMFieldMap, EDUCATION_LEVEL } from 'constants/index';
import { add, getTime } from 'date-fns';
import {
  getDealInfo as getDealInfoApi,
  getContactInfo as getContactInfoApi,
  getGradeList as getGradeListApi,
  updateDealInfo as updateDealInfoApi,
} from 'services/api/bitrix';
import { registerMobileOnly as registerMobileOnlyApi } from 'services/api/customerService';
import { getIsAccountExist as getIsAccountExistApi } from 'services/api/electronic';
import {
  addUserToGroup as addUserToGroupApi,
  getCustomer as getCustomerApi,
} from 'services/api/organization/customer';
import { useClass } from 'store/class';
import { useCustomer } from 'store/customer';
import { useAlert } from 'utils/hooks/useAlert';
import { useAllSchoolYear } from 'utils/hooks/useSchoolYear';
import {
  UiVipPassForm,
  UiTitle,
  UiFormContainer,
  UiDealIdBox,
  UiMuiTextField,
} from './VipPassForm.style';


/**
 * 快速通道表單
 */

const TITLE = '快速通道';
const BTN_CHECK_TEXT = '檢查';
const BUTTON_PADDING = '5px 30px';
const ONECLUB_PASSWORD = 'abcd1234';
const NEW_CLASS_OWNER_ID = 'DoraChangE';
const FORM = {
  dealId: {
    label: 'Bitrix交易編號*',
    helperText: '點擊檢查將自動帶入下列資訊',
  },
  mobileNumber: {
    label: '家長手機*',
    helperText: '家長手機即為OneClub ID',
  },
  studentName: { label: '學生姓名*' },
  oneClubId: { label: 'OneClub ID*' },
  className: { label: '班級名稱*' },
};
const SELECT = {
  crmField: { label: '試聽資訊' },
  edu: { label: '學制' },
  grade: { label: '年級' },
};
const ERROR = {
  fetchIsAccountExist: 'OneClub 帳號已存在',
  getEduSubject: '無法取得學制、年級',
  getDealInfo: '無法取得 bitrix 訂單資訊',
  getContactInfo: '無法取得 bitrix 聯絡資訊',
  getIsExistInOrganization: '無法驗證帳號是否存在於機構內',
  getGradeList: '查無 bitrix 年級表',
  registerOneClubAccount: '無法建立 oneClub 帳號',
  updateBitrixDeal: '無法更新 Bitrix 訂單',
  addOneClubIdToLive: '無法將 oneClub 帳號加入至 live',
  createClass: '無法建立班級',
  addStudentToClass: '無法將學生加入班級',
  dealId: '交易編號不存在',
  mobileNumber: '帳號已存在',
  studentName: '資料不足或包含多筆姓名，請手動輸入',
  oneClubId: '此交易編號已建立OneClub ID',
  className: '請輸入班級名稱',
  other: '其他錯誤',
};
const EDU_OPTIONS = [
  { name: '國小', value: EDUCATION_LEVEL.ELEMENTARY },
  { name: '國中', value: EDUCATION_LEVEL.JUNIOR },
  { name: '高中', value: EDUCATION_LEVEL.SENIOR },
];
const BITRIX_EDU_GRADE = [
  { edu: 'E', grade: '1' },
  { edu: 'E', grade: '2' },
  { edu: 'E', grade: '3' },
  { edu: 'E', grade: '4' },
  { edu: 'E', grade: '5' },
  { edu: 'E', grade: '6' },
  { edu: 'J', grade: '7' },
  { edu: 'J', grade: '8' },
  { edu: 'J', grade: '9' },
  { edu: 'H', grade: '10' },
  { edu: 'H', grade: '11' },
  { edu: 'H', grade: '12' },
];
const CRM_FIELD_OPTIONS = [
  { name: '試聽資訊1', value: CRMField.fieldId_1 },
  { name: '試聽資訊2', value: CRMField.fieldId_2 },
  { name: '試聽資訊3', value: CRMField.fieldId_3 },
  { name: '試聽資訊4', value: CRMField.fieldId_4 },
];
const GRADE_OPTIONS = [
  { name: '1 年級', value: '1' },
  { name: '2 年級', value: '2' },
  { name: '3 年級', value: '3' },
  { name: '4 年級', value: '4' },
  { name: '5 年級', value: '5' },
  { name: '6 年級', value: '6' },
  { name: '7 年級', value: '7' },
  { name: '8 年級', value: '8' },
  { name: '9 年級', value: '9' },
  { name: '10 年級', value: '10' },
  { name: '11 年級', value: '11' },
  { name: '12 年級', value: '12' },
];

const initialFormData = {
  dealId: '',
  mobileNumber: '',
  crmField: CRMField.fieldId_1,
  studentName: '',
  edu: 'E',
  grade: '1',
  oneClubId: '',
  className: '',
};
const initialIsFormError = {
  dealId: false,
  mobileNumber: false,
  crmField: false,
  studentName: false,
  edu: false,
  grade: false,
  oneClubId: false,
  className: false,
};

export const VipPassForm = () => {
  const [bitrixDealInfoOri, setBitrixDealInfoOri] = useState({});
  const [bitrixGradeListOri, setBitrixGradeListOri] = useState({});
  const [isLoadingEduSubject, setIsLoadingEduSubject] = useState(true);
  const [isLoadingCheckDealId, setIsLoadingCheckDealId] = useState(false);
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
  const [formData, setFormData] = useState(initialFormData);
  const [isFormError, setIsFormError] = useState(initialIsFormError);
  const [, { inviteCustomers }] = useCustomer();
  const [, { createClassInfo }] = useClass();
  const { schoolYearOptions } = useAllSchoolYear();
  const { setAlert } = useAlert();
  const { organizationId } = useParams();
  const history = useHistory();
  const createClassResponseRef = useRef(null);
  const isHome = Boolean(useRouteMatch({ path: '/home' }));
  const isBitrixOneClubIdFilled = !!bitrixDealInfoOri[CRMField.oneClubId];  // Bitrix OneClubID 是否已填寫
  const isDisabledSubmitBtn = (!formData.dealId ||
                               !formData.mobileNumber ||
                               !formData.studentName ||
                               !formData.className);

  /* 取得 Bitrix 學制、年級 */
  const getBitrixEduGrade = (dealInfo, crmFieldId) => {
    // const crmFieldId = formData.crmField;               // 試聽資訊 id
    const gradeFieldId = CRMFieldMap[crmFieldId].grade; // 年級欄位 id
    const grades = bitrixGradeListOri[crmFieldId];      // 年級 id 對應之年級表
    const gradeId = dealInfo[gradeFieldId] ? dealInfo[gradeFieldId] : grades[0].ID;    // 年級 id
    const bitrixGrade = grades.find(({ ID }) => ID === gradeId).VALUE;            // bitrix 年級
    const bitrixEduGrade = BITRIX_EDU_GRADE.find(({ grade }) => grade === bitrixGrade); // 對應之學制年級
    return bitrixEduGrade;
  };

  /* 打 API，取得 Bitrix 年級表 */
  const fetchGradeInfo = async () => {
    const params = {
      CRMField1: { gradeFiledId: CRMFieldMap[CRMField.fieldId_1].grade },
      CRMField2: { gradeFiledId: CRMFieldMap[CRMField.fieldId_2].grade },
      CRMField3: { gradeFiledId: CRMFieldMap[CRMField.fieldId_3].grade },
      CRMField4: { gradeFiledId: CRMFieldMap[CRMField.fieldId_4].grade },
    };
    const responses = await Promise.all([getGradeListApi(params.CRMField1),
    getGradeListApi(params.CRMField2),
    getGradeListApi(params.CRMField3),
    getGradeListApi(params.CRMField4)]);
    
    for (let i = 0; i < responses.length; i++) {
      const { status } = responses[i];
      if (status === 'failure') return;
    }

    const data = {
      [CRMField.fieldId_1]: responses[0].data.LIST,
      [CRMField.fieldId_2]: responses[1].data.LIST,
      [CRMField.fieldId_3]: responses[2].data.LIST,
      [CRMField.fieldId_4]: responses[3].data.LIST,
    };
    setBitrixGradeListOri(data);
    setIsLoadingEduSubject(false);
  };

  /* 初始化 formData */
  const resetFormData = () => {
    const newInitialFormData = { ...initialFormData };
    setFormData(newInitialFormData);
    setBitrixDealInfoOri({});
  };

  /* 初始化 isFormError */
  const resetIsFormError = () => {
    setIsFormError(initialIsFormError);
  };

  /* change TextField */
  const changeFormForTextField = (key) => (e) => {
    e.persist();
    const rules = {
      dealId: /[^0-9]+$/,
      mobileNumber: /[^0-9]+$/,
      oneClubId: /[^A-Za-z0-9]+$/,
    };
    if (key === 'dealId' || key === 'mobileNumber' || key === 'oneClubId') {
      const value = e.target.value;
      const newValue = value.replace(rules[key], '');
      setFormData(prev => ({ ...prev, [key]: newValue }));
    } else if (key === 'studentName') {
      const className = `(試聽)${e.target.value}#${formData.dealId}`;
      setFormData(prev => ({ ...prev, [key]: e.target.value, className }));
    } else {
      setFormData(prev => ({ ...prev, [key]: e.target.value }));
    }
  };

  /* change Select */
  const changeFormForSelect = (key) => (value) => {
    if (key === 'grade') {
      const { edu, grade } = BITRIX_EDU_GRADE.find(({ grade }) => grade === value );
      setFormData(prev => ({ ...prev, edu, grade }));
    } else if (key === 'crmField') {
      const { edu, grade } = getBitrixEduGrade(bitrixDealInfoOri, value);
      const studentName = bitrixDealInfoOri[CRMFieldMap[value].studentName];
      const className = `(試聽)${studentName}#${formData.dealId}`;
      setFormData(prev => ({ ...prev, studentName, edu, grade, className }));
    } else {
      setFormData(prev => ({ ...prev, [key]: value }));
    }
  };



  /* 打 API，取得帳號是否存在於 oneClub */
  const fetchIsAccountExist = async () => {
    const result = { isSuccess: true, index: 1, errorMsg: null };
    const account = formData.oneClubId;
    const { status, data } = await getIsAccountExistApi({ account });
    if (status === 'success') {
      const { status, error } = data;
      if (status === 'failure' && error.errorCode === 20115) {
        // 帳號已存在於 OneClub
        result.isSuccess = false;
        result.errorMsg = ERROR.fetchIsAccountExist;
      } else if (status === 'failure') {
        // 其他錯誤
        result.isSuccess = false;
        result.errorMsg = `判斷 OneClub 帳號是否存在＿${ERROR.other}`;
      }
    } else {
      // 無法驗證 OneClub ID
      result.isSuccess = false;
      result.errorMsg = `判斷 OneClub 帳號是否存在＿${ERROR.other}`;
    }
    return result;
  };
  /* 打 API，建立 oneClub 帳號 */
  const registerOneClubAccount = async () => {
    const result = { isSuccess: true, index: 1, errorMsg: null };
    const params = { mobile: formData.oneClubId, password: ONECLUB_PASSWORD };
    const { status } = await registerMobileOnlyApi(params);
    if (status === 'failure') {
      // 帳號已存在
      result.isSuccess = false;
      result.errorMsg = ERROR.registerOneClubAccount;
    }
    return result;
  };
  /* 打 API，更新 Bitrix 訂單 */
  const updateBitrixDeal = async () => {
    const result = { isSuccess: true, index: 1, errorMsg: null };
    const payload = {
      dealId: formData.dealId,
      fieldIdOneClubId: CRMField.oneClubId,
      oneClubId: formData.oneClubId,
    };
    const { status } = await updateDealInfoApi(payload);
    if (status === 'failure') {
      throw new Error(ERROR.updateBitrixDeal);
    }
    return result;
  };
  /* 打 API，是否存在於機構 */
  const getIsExistInOrganization = async () => {
    const result = { isSuccess: true, index: 1, errorMsg: null };
    const customerId = formData.oneClubId;
    const { error, status } = await getCustomerApi(organizationId, customerId)();
    if (status === 'success') {
      // 已在機構內
      result.index = 2;
    }
    else if (status === 'failure') {
      const { errorCode } = error;
      if (errorCode !== 20004) {
        // 其他錯誤
        result.isSuccess = false;
        result.errorMsg = ERROR.getIsExistInOrganization;
      }
    }
    return result;
  };
  /* 打 API，將帳號加入至 live 機構 */
  const addOneClubIdToLive = async () => {
    const result = { isSuccess: true, index: 1, errorMsg: null };
    const { oneClubId, studentName } = formData;
    const nickname = `(試聽)${studentName}#${formData.dealId}`;
    const params = { customerId: oneClubId, nickname };
    const { error, status } = await inviteCustomers(params);
    if (status === 'failure') {
      const { errorCode } = error;
      if (errorCode === 20006) {
        // 學生已經在機構內
        result.index = 2;
      } else {
        // 其他錯誤
        result.isSuccess = false;
        result.errorMsg = ERROR.addOneClubIdToLive;
      }
    }
    return result;
  };
  /* 打 API，建班級 */
  const createClass = async () => {
    const result = { isSuccess: true, index: 1, errorMsg: null };
    const params = {
      description: '',
      educationName: formData.edu,
      expirationTime: getTime(add(Date.now(), { years: 1, days: 1 })),
      grades: [formData.grade],
      name: formData.className,
      ownerId: NEW_CLASS_OWNER_ID,
      thumbnailUrl: '',
      year: schoolYearOptions[0].value,
    };
    const { data, isSuccess } = await createClassInfo(params);
    if (isSuccess) {
      // 建班級成功
      createClassResponseRef.current = data;
    } else {
      // 建班級失敗
      result.isSuccess = false;
      result.errorMsg = ERROR.createClass;
    }
    return result;
  };
  /* 打 API，加入學生至班級 */
  const addStudentToClass = async () => {
    const result = { isSuccess: true, index: 1, errorMsg: null };
    const params = { userIdList: [formData.oneClubId] };
    const classId = createClassResponseRef.current.id;
    const { status } = await addUserToGroupApi(isHome)(organizationId, classId)(params);
    if (status === 'failure') {
      result.isSuccess = false;
      result.errorMsg = ERROR.addStudentToClass;
    }
    return result;
  };
  /* 送出表單 */
  const submitForm = async () => {
    try {
      const apis = [
        fetchIsAccountExist,      // 帳號是否存在於 OneClub
        registerOneClubAccount,   // 註冊 OneClub
        updateBitrixDeal,         // 更新 Bitrix 訂單
        getIsExistInOrganization, // 帳號是否存在於 live 機構
        addOneClubIdToLive,       // 加帳號至 live 機構
        createClass,              // 建立班級
        addStudentToClass,        // 加學生到班級
      ];
      let apisIndex = isBitrixOneClubIdFilled ? 3 : 0;

      setIsLoadingSubmit(true);
      resetIsFormError();
      while (apisIndex < apis.length) {
        const { isSuccess, index, errorMsg } = await apis[apisIndex]();
        if (isSuccess) apisIndex += index;
        else throw new Error(errorMsg);
      }
      resetFormData();
    }
    catch (errorMsg) {
      setAlert(errorMsg, 'error');
    }
    finally {
      setIsLoadingSubmit(false);
    }
  };



  /* 打 API，取得 Bitrix 訂單資訊 */
  const fetchDealInfo = async () => {
    const { dealId } = formData;
    const { error, errorMsg, result, status } = await getDealInfoApi({ dealId });
    if (status === 'failure') {
      resetFormData();
      setIsFormError({ ...isFormError, dealId: true });
      if (error.toString().includes('TypeError: Failed to fetch')) {
        throw new Error(ERROR.getDealInfo);
      } else {
        throw new Error(errorMsg);
      }
    }
    return result;
  };
  /* 打 API，取得 Bitrix 聯絡資訊 */
  const fetchContactInfo = async (dealInfo) => {
    const contactId = dealInfo.CONTACT_ID;
    const { error, errorMsg, result, status } = await getContactInfoApi({ contactId });
    if (status === 'failure') {
      if (error.toString().includes('TypeError: Failed to fetch')) {
        throw new Error(ERROR.getContactInfo);
      } else {
        throw new Error(errorMsg);
      }
    }
    return result;
  };
  /* 取得 bitrix 資料後，設定至 form 表單 */
  const setFormDataByBitrixDealId = (dealInfo, contactInfo) => {
    const rules = {
      mobileNumber: /[^0-9]/g,
    };

    const mobileNumber = contactInfo.PHONE[0].VALUE.replace(rules.mobileNumber, '');
    const crmFieldId = CRMField.fieldId_1;
    const studentName = dealInfo[CRMFieldMap[crmFieldId].studentName];
    const oneClubId = dealInfo[CRMField.oneClubId] ? dealInfo[CRMField.oneClubId] : `a${mobileNumber}`;
    const className = `(試聽)${studentName}#${formData.dealId}`;
    const { edu, grade } = getBitrixEduGrade(dealInfo, crmFieldId);
    setFormData(prev => ({
      ...prev,
      mobileNumber,
      crmField: crmFieldId,
      studentName,
      edu,
      grade,
      oneClubId,
      className
    }));
  };
  /* 檢查 Bitrix 交易編號 */
  const checkDealId = async () => {
    setIsLoadingCheckDealId(true);
    resetIsFormError();
    try {
      const dealInfo = await fetchDealInfo();
      const contactInfo = await fetchContactInfo(dealInfo);
      setFormDataByBitrixDealId(dealInfo, contactInfo);
      setBitrixDealInfoOri(dealInfo);
    } catch (error) {
      setAlert(error, 'error');
    } finally {
      setIsLoadingCheckDealId(false);
    }
  };



  /* 取得 Bitrix 年級 */
  useEffect(() => {
    fetchGradeInfo();
  }, []);


  return (
    <UiVipPassForm>
      <UiTitle variant='h5' component='h5'>{TITLE}</UiTitle>
      <UiFormContainer>
        {/* Bitrix 交易編號 */}
        <UiDealIdBox>
          <Box>
            <UiMuiTextField
              autoFocus
              error={isFormError.dealId}
              fullWidth
              disabled={isLoadingCheckDealId}
              helperText={isFormError.dealId ? ERROR.dealId : FORM.dealId.helperText}
              label={FORM.dealId.label}
              onChange={changeFormForTextField('dealId')}
              onKeyDown={e => e.key === 'Enter' && checkDealId()}
              value={formData.dealId}
              variant="outlined" />
          </Box>
          <Box>
            <Button
              buttonPadding={BUTTON_PADDING}
              loading={isLoadingCheckDealId}
              onClick={checkDealId}
            >
              {BTN_CHECK_TEXT}
            </Button>
          </Box>
        </UiDealIdBox>

        {/* 家長手機 */}
        <UiMuiTextField
          error={isFormError.mobileNumber}
          helperText={isFormError.mobileNumber && ERROR.mobileNumber}
          label={FORM.mobileNumber.label}
          onChange={changeFormForTextField('mobileNumber')}
          value={formData.mobileNumber}
          variant="outlined" />

        {/* 試聽資訊 */}
        <Select
          label={SELECT.crmField.label}
          options={CRM_FIELD_OPTIONS}
          submitHandler={changeFormForSelect('crmField')}
          value={formData.crmField}
          width='100%' />

        {/* 學生姓名 */}
        <UiMuiTextField
          error={isFormError.studentName}
          helperText={isFormError.studentName && ERROR.studentName}
          label={FORM.studentName.label}
          onChange={changeFormForTextField('studentName')}
          value={formData.studentName}
          variant="outlined" />

        {/* 學制 */}
        <Select
          label={SELECT.edu.label}
          loading={isLoadingEduSubject}
          options={EDU_OPTIONS}
          submitHandler={changeFormForSelect('edu')}
          value={formData.edu}
          width='100%'
          disabled />

        {/* 年級 */}
        <Select
          label={SELECT.grade.label}
          loading={isLoadingEduSubject}
          options={GRADE_OPTIONS}
          submitHandler={changeFormForSelect('grade')}
          value={formData.grade}
          width='100%' />

        {/* OneClub ID */}
        <UiMuiTextField
          helperText={isBitrixOneClubIdFilled && ERROR.oneClubId}
          label={FORM.oneClubId.label}
          onChange={changeFormForTextField('oneClubId')}
          value={formData.oneClubId}
          variant='outlined'
          disabled={isBitrixOneClubIdFilled}
          />

        {/* 班級名稱 */}
        <UiMuiTextField
          error={isFormError.className}
          helperText={isFormError.className && ERROR.className}
          label={FORM.className.label}
          onChange={changeFormForTextField('className')}
          value={formData.className}
          variant="outlined" />

        <Box py={1} display="flex" justifyContent="flex-end">
          <Box mr={1}>
            <Button
              buttonPadding={BUTTON_PADDING}
              loading={isLoadingSubmit}
              onClick={() => history.goBack()}
            >
              返回
            </Button>
          </Box>
          <Box>
            <Button
              buttonPadding={BUTTON_PADDING}
              disabled={isDisabledSubmitBtn}
              loading={isLoadingSubmit}
              onClick={submitForm}
            >
              送出
            </Button>
          </Box>
        </Box>
      </UiFormContainer>
    </UiVipPassForm>
  );
};

VipPassForm.propTypes = {
};


