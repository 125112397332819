/**
 * 取得帳號是否存在於 oneClub
 * @param {object} { account }
 */
export const getIsAccountExist = async (params) => {
  const { account } = params;
  const response = await fetch(`${process.env.REACT_APP_ELECTRONIC_COMMERCE_API_DOMAIN}/services/${account}`);
  const json = response.json();
  return json;
};

/**
 * 建立 oneClub 帳號
 * @param {object} params { userAccount, password }
 */
export const createOneClubAccount = async (params) => {
  const requestInit = {
    method: 'POST',
    body: JSON.stringify(params),
    headers: new Headers({ 'Content-Type': 'application/json' }),
  };
  const response = await fetch(`${process.env.REACT_APP_ELECTRONIC_COMMERCE_API_DOMAIN}/services`, requestInit);
  const json = response.json();
  return json;
};