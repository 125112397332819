import {
  EDIT_STATUS,
  PERMISSIONS,
} from 'constants/index';
import {
  BsLayout,
  BsHomePage,
  PdfUploadPage,
  TeacherManagementPage,
  TeacherManagementFormPage,
  TeacherManagementInvitingPage,
  ClassPage,
  ClassFormPage,
  SessionManagementPage,
  SessionReportPage,
  OrganizationSessionFormPage,
  CustomersPage,
  CustomerInvitingPage,
  CustomerFormPage,
  SessionSummaryPage,
  OrganizationEdit,
  PermissionSettingPage,
  BsPublicResourceManagementPage,
  ExamPage,
  StudentsAnswerPage,
  MonitorSessionPage,
} from 'components';

const routes = [
  {
    path: '/organization/:organizationId',
    component: BsLayout,
    routes: [
      {
        path: '/organization/:organizationId',
        component: BsHomePage,
        exact: true
      },
      {
        path: '/organization/:organizationId/resources',
        component: PdfUploadPage,
        exact: true,
        permission: PERMISSIONS.MANAGE_RESOURCE
      },
      {
        path: '/organization/:organizationId/resources/public',
        component: BsPublicResourceManagementPage,
        exact: true,
        permission: PERMISSIONS.MANAGE_RESOURCE
      },
      {
        path: '/organization/:organizationId/teachers',
        component: TeacherManagementPage,
        exact: true,
        permission: PERMISSIONS.VIEW_STAFF_PROFILE
      },
      {
        path: `/organization/:organizationId/teachers/${EDIT_STATUS.CREATE}`,
        component: TeacherManagementFormPage,
        exact: true,
        permission: PERMISSIONS.ADD_STAFF,
        editStatus: EDIT_STATUS.CREATE
      },
      {
        path: `/organization/:organizationId/teachers/:teacherId/${EDIT_STATUS.EDIT}`,
        component: TeacherManagementFormPage,
        exact: true,
        permission: PERMISSIONS.EDIT_STAFF_PROFILE,
        editStatus: EDIT_STATUS.EDIT,
      },
      {
        path: `/organization/:organizationId/teachers/${EDIT_STATUS.READ}`,
        component: TeacherManagementFormPage,
        exact: true,
        permission: PERMISSIONS.VIEW_STAFF_PROFILE,
        editStatus: EDIT_STATUS.READ
      },
      {
        path: '/organization/:organizationId/teachers/inviting',
        component: TeacherManagementInvitingPage,
        exact: true
      },
      {
        path: '/organization/:organizationId/class',
        component: ClassPage,
        permission: PERMISSIONS.VIEW_GROUP,
        exact: true
      },
      {
        path: `/organization/:organizationId/class/${EDIT_STATUS.CREATE}`,
        component: ClassFormPage,
        permission: PERMISSIONS.MANAGE_GROUP,
        exact: true
      },
      {
        path: '/organization/:organizationId/class/session',
        component: SessionManagementPage,
        exact: true,
        permission: PERMISSIONS.MANAGE_SESSION
      },
      {
        path: `/organization/:organizationId/class/session/${EDIT_STATUS.CREATE}`,
        component: OrganizationSessionFormPage,
        exact: true,
        permission: PERMISSIONS.MANAGE_SESSION,
        editStatus: EDIT_STATUS.CREATE
      },
      {
        path: '/organization/:organizationId/class/session/:sessionId/timespan/:timeSpanId/report',
        component: SessionReportPage,
        exact: true,
        permission: PERMISSIONS.VIEW_SESSION_REPORT
      },
      {
        path: `/organization/:organizationId/class/:classId/${EDIT_STATUS.EDIT}`,
        component: ClassFormPage,
        permission: PERMISSIONS.MANAGE_GROUP,
        exact: true,
        editStatus: EDIT_STATUS.EDIT,
      },
      {
        path: '/organization/:organizationId/class/:classId/session',
        component: SessionManagementPage,
        exact: true,
        permission: PERMISSIONS.MANAGE_SESSION
      },
      {
        path: `/organization/:organizationId/class/:classId/session/${EDIT_STATUS.CREATE}`,
        component: OrganizationSessionFormPage,
        exact: true,
        permission: PERMISSIONS.MANAGE_SESSION,
        editStatus: EDIT_STATUS.CREATE,
        isOrganization: true
      },
      {
        path: `/organization/:organizationId/class/:classId/session/:sessionId/${EDIT_STATUS.EDIT}`,
        component: OrganizationSessionFormPage,
        exact: true,
        permission: PERMISSIONS.MANAGE_SESSION,
        editStatus: EDIT_STATUS.EDIT,
        isOrganization: true
      },
      {
        path: `/organization/:organizationId/class/:classId/session/:sessionId/${EDIT_STATUS.COPY}`,
        component: OrganizationSessionFormPage,
        exact: true,
        permission: PERMISSIONS.MANAGE_SESSION,
        editStatus: EDIT_STATUS.COPY,
        isOrganization: true
      },
      {
        path: '/organization/:organizationId/class/:classId/session/:sessionId/timespan/:timeSpanId/report',
        component: SessionReportPage,
        exact: true,
        permission: PERMISSIONS.VIEW_SESSION_REPORT,
      },
      {
        path: '/organization/:organizationId/session/:sessionId/timespan/:timeSpanId/summary',
        component: SessionSummaryPage,
        exact: true,
        permission: PERMISSIONS.VIEW_SESSION_REPORT
      },
      {
        path: '/organization/:organizationId/customer',
        component: CustomersPage,
        exact: true,
        permission: PERMISSIONS.VIEW_CUSTOMER_PROFILE,
      },
      {
        path: '/organization/:organizationId/class/:classId/customer',
        component: CustomersPage,
        exact: true,
        permission: PERMISSIONS.VIEW_CUSTOMER_PROFILE
      },
      {
        path: `/organization/:organizationId/customer/${EDIT_STATUS.CREATE}`,
        component: CustomerFormPage,
        exact: true,
        editStatus: EDIT_STATUS.CREATE,
        permission: PERMISSIONS.INVITE_CUSTOMER
      },
      {
        path: `/organization/:organizationId/customer/:customerId/${EDIT_STATUS.READ}`,
        component: CustomerFormPage,
        exact: true,
        editStatus: EDIT_STATUS.READ,
        permission: PERMISSIONS.VIEW_CUSTOMER_PROFILE
      },
      {
        path: `/organization/:organizationId/customer/:customerId/${EDIT_STATUS.EDIT}`,
        component: CustomerFormPage,
        exact: true,
        editStatus: EDIT_STATUS.EDIT,
        permission: PERMISSIONS.EDIT_CUSTOMER_PROFILE
      },
      {
        path: '/organization/:organizationId/customer/inviting',
        component: CustomerInvitingPage,
        exact: true,
      },
      { // 看課管理
        path: '/organization/:organizationId/monitor-session',
        component: MonitorSessionPage,
        exact: true,
        permission: PERMISSIONS.MONITOR_SESSION,
      },
      {
        path: '/organization/:organizationId/setting',
        component: OrganizationEdit,
        exact: true,
        editStatus: EDIT_STATUS.EDIT,
        permission: PERMISSIONS.EDIT_ORGANIZATION_SETTING,
        isAdmin: false
      },
      {
        path: '/organization/:organizationId/permissionSetting',
        component: PermissionSettingPage,
        exact: true,
        editStatus: EDIT_STATUS.EDIT,
        permission: PERMISSIONS.EDIT_ORGANIZATION_SETTING,
        isAdmin: false
      },
      {
        path: '/organization/:organizationId/class/:classId/exam',
        component: ExamPage,
        exact: true,
        // editStatus: EDIT_STATUS.EDIT,
        // permission: PERMISSIONS.EDIT_ORGANIZATION_SETTING,
        isAdmin: false
      },
      {
        path: '/organization/:organizationId/class/:classId/exam/:examId/results',
        component: StudentsAnswerPage,
        exact: true,
        isAdmin: false
      },
    ]
  }
];

export default routes;
