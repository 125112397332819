/* eslint-disable max-len */
import { stringify } from 'query-string';
/**
 * 取得 Bitrix 訂單資訊
 * @param {object} params { dealId }
 * @returns {object}
 */
 export const getDealInfo = async (params) => {
  const { dealId } = params;
  try {
    const response = await fetch(`${process.env.REACT_APP_BITRIX_PROD_API_DOMAIN}/9/aqw6va2ru41h8d7t/crm.deal.get.json?id=${dealId}`);
    const json = await response.json();
    const { error, error_description, result } = json;
    if (!result) {
      return {
        status: 'failure',
        error,
        errorMsg: error_description,
        result: {},
      };
    } else {
      return {
        status: 'success',
        result,
      };
    }
  } catch (error) {
    return {
      status: 'failure',
      error: error,
      result: {},
    };
  }
};

/**
 * 取得 Bitrix 聯絡資訊
 * @param {object} params { contactId }
 * @returns 
 */
export const getContactInfo = async (params) => {
  const { contactId } = params;
  try {
    const response = await fetch(`${process.env.REACT_APP_BITRIX_PROD_API_DOMAIN}/9/aqw6va2ru41h8d7t/crm.contact.get.json?id=${contactId}`);
    const json = await response.json();
    const { error, error_description, result } = json;
    if (!result) {
      return {
        status: 'failure',
        error,
        errorMsg: error_description,
        result: {},
      };
    } else {
      return {
        status: 'success',
        result,
      };
    }
  } catch (error) {
    return {
      status: 'failure',
      error: error,
      result: {},
    };
  }
};

/**
 * 更新 Bitrix 訂單資訊
 * @param {*} params 
 * @returns 
 */
export const updateDealInfo = async (params) => {
  const { dealId, fieldIdUrl, url, fieldIdBook, bookIds, fieldIdTeacher, teacherName, fieldIdOneClubId, oneClubId } = params;
  const fieldsUrl = `FIELDS[${fieldIdUrl}]`;
  const fieldsBook = `FIELDS[${fieldIdBook}]`;
  const fieldsTeacher = `FIELDS[${fieldIdTeacher}]`;
  const fieldsOneClubId = `FIELDS[${fieldIdOneClubId}]`;
  const querystring = stringify({
    id: dealId,
    [fieldsUrl]: url,
    [fieldsBook]: bookIds,
    [fieldsTeacher]: teacherName,
    [fieldsOneClubId]: oneClubId,
  }, { arrayFormat: 'index' });
  try {
    const response = await fetch(`${process.env.REACT_APP_BITRIX_PROD_API_DOMAIN}/9/sbrsgo8c3qxoxouz/crm.deal.update.json?${querystring}`);
    const json = await response.json();
    return {
      ...json,
      status: 'success',
    };
  } catch (error) {
    return {
      status: 'failure',
      error: error,
      data: {},
    };
  }
};

/**
 * 取得 Bitrix 年級表
 * @param {object} params { gradeField: string }
 * @returns 
 */
export const getGradeList = async (params) => {
  const { gradeFiledId } = params;
  const querystring = stringify({ 'FILTER[FIELD_NAME]': gradeFiledId }, { arrayFormat: 'index' });
  try {
    const response = await fetch(`${process.env.REACT_APP_BITRIX_PROD_API_DOMAIN}/176/khh0ore3ktp012ua/crm.deal.userfield.list.json?${querystring}`);
    const { result } = await response.json();
    if (!result.length) throw Error;
    return {
      data: result[0],
      status: 'success',
    };
  } catch (error) {
    return {
      status: 'failure',
      error: error,
      data: {},
    };
  }
};