import styled from 'styled-components';
import { Box, Container, TextField, Typography } from '@material-ui/core';

export const UiVipPassForm = styled.div`
	display: block;
`;

export const UiTitle = styled(Typography)`
	margin-bottom: 40px;
`;

export const UiFormContainer = styled(Container)`
	display: flex;
	flex-direction: column;
	row-gap: 32px;
	max-width: 682px;
	margin: 0;
	padding: 0;
`;

export const UiDealIdBox = styled(Box)`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	gap: 12px;
`;

export const UiMuiTextField = styled(TextField)`
	input[type=number]::-webkit-inner-spin-button, 
	input[type=number]::-webkit-outer-spin-button { 
		-webkit-appearance: none; 
		margin: 0; 
	}
`;